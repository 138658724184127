// 登录页面

import { useState, useEffect } from 'react'
import { message, Form, Input, Button, Modal, notification } from 'antd'
import fun from '../../common/common_fun'
import url from '../../common/url_config'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import box from './../../img/logo_mini.jpg'


function Login() {
  let [username, setUsernamer] = useState('')
  let [password, setPassword] = useState('')
  let [isLoading, setIsLoading] = useState(false)
  let [isModalVisible, setIsModalVisible] = useState(false)
  let [phone, set_phone] = useState('')
  let [code, set_code] = useState('')

  const handleSubmit = () => {
    if (isLoading) {
      return
    }
    if (username === '' || password === '') {
      alert('请正确填写账号密码！')
      return
    }
    setIsLoading(true)
    fun['post_data'](url.head + 'login_haotian', { username, password }).then(res => {
      if (res.status) {
        // if (res.result['用户角色'] === '客户' || res.result['用户角色'] === '卡口统计' || res.result['用户角色'] === '财务会计' || res.result['用户角色'] === '财务核算') {
        message.success('登录成功')
        cookie.save('username', res.result['登录账号'], { path: '/' })
        cookie.save('login_id', res.result.id, { path: '/' })
        cookie.save('login_role', res.result['用户角色'], { path: '/' })
        cookie.save('session_key', res.result['session_key'], { path: '/' })
        cookie.save('client_name', res.result['客户名称'], { path: '/' })
        cookie.save('current_complete_num_in_yard', '0', { path: '/' })
        cookie.save('current_complete_num_out_yard', '0', { path: '/' })

        setTimeout(() => {
          if (res.result['用户角色'] === '财务会计' || res.result['用户角色'] === '财务核算') {
            window.location.href = '/work_detail'
          } else if (res.result['用户角色'] === '重机司机') {
            window.location.href = '/zhongji/in_yard'
          } else {
            window.location.href = '/check'
          }
          // window.location.reload()
        }, 500)
        // } else {
        //   alert('登录失败: 角色错误')
        // }
      } else {
        alert('登录失败: ' + JSON.stringify(res.result))
      }
      setIsLoading(false)
    })
  }

  useEffect(() => {
    // notification.warning({
    //   message: '若登录异常，请清除缓存',
    //   description: 'Ctrl+Shift+Delete即可清除缓存，选择清除全部',
    //   placement: 'bottomRight',
    //   duration: null,
    // });
  }, [])

  return (
    <div style={{ paddingTop: 100 }} className='login_root'>
      <Modal width={400} onCancel={() => setIsModalVisible(false)} destroyOnClose footer={null} title={'注册用户'} visible={isModalVisible} >
        <Input addonBefore='手机号' placeholder='手机号' value={phone} onChange={e => set_phone(e.target.value)} />
        <Input addonAfter={<Button loading={isLoading} disabled={!phone || phone.length !== 11} size='small' onClick={() => {
          setIsLoading(true)
          fun['post_data'](url['send_code'], { phone }).then(res => {
            if (res.status) {
              message.success('发送成功，30分钟内有效！')
            } else {
              alert('发送失败: ' + JSON.stringify(res.result))
            }
            setIsLoading(false)
          })
        }}>发送验证码</Button>} style={{ marginTop: 10 }} addonBefore='验证码' placeholder='验证码' value={code} onChange={e => set_code(e.target.value)} />
        <Button loading={isLoading} style={{ marginTop: 30, width: '100%' }} type='primary' onClick={() => {
          if (!phone || !code) {
            alert('请输入手机号和验证码！')
            return
          }
          setIsLoading(true)
          fun['post_data'](url['reg_new_user'], { phone, code }).then(res => {
            if (res.status) {
              message.success('注册成功，请使用手机号和密码登陆！')
              setUsernamer(phone)
              setIsModalVisible(false)
            } else {
              alert('注册失败: ' + JSON.stringify(res.result))
            }
            setIsLoading(false)
          })
        }}>确认注册</Button>
      </Modal>

      <h2 style={{ color: '#1890ff', padding: '20px 0', fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img style={{ width: 55, height: 55 }} src={box} /><span style={{ fontSize: 22, marginLeft: 12 }}>浩田集装箱管理登陆</span>
      </h2>
      <Form onFinish={handleSubmit} className="login-form">
        <Form.Item>
          <Input
            size='large'
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="用户名/手机号"
            value={username}
            onChange={e => setUsernamer(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Input.Password
            size='large'
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="密码"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button style={{ height: '40px' }} type="primary" htmlType="submit" className="login-form-button">{isLoading ? '登录中...' : '登录'}</Button>
        </Form.Item>
        {/* <p onClick={() => setIsModalVisible(true)} style={{ fontWeight: 'bold', color: '#1890ff', textAlign: 'center', marginTop: 20, cursor: 'pointer' }}>没有账号？立即注册</p> */}
      </Form>
      {/* <p style={{ textAlign: 'center', color: '#333333', position: 'fixed', bottom: 0, width: '100%', left: 0 }}>版权所有：希得（天津）信息技术有限公司</p> */}
    </div>
  )
}
export default Login