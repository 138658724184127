// 公共函数

import cookie from 'react-cookies'

const ua = navigator.userAgent;
const ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
  isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
  isAndroid = ua.match(/(Android)\s+([\d.]+)/),
  isMobile = isIphone || isAndroid;

// post请求发送paras返回json
const post_data = (url: string, paras = {}) => {
  return fetch(url, {
    method: 'post',
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...paras,
      login_id: cookie.load('login_id'),
      login_role: cookie.load('login_role'),
      login_key: cookie.load('login_key'),
      session_key: cookie.load('session_key'),
    }),
  }).then(res => res.json()).catch(res => ({ status: false, result: '网络请求错误' }))
}

// 为数组data添加索引
const add_index_for_fun = (arr: [any]) => {
  arr.map((item, index) => {
    item.index = index
    item.process_status_button = false
  })
  return arr
}

// 格式化路由数据
const format_router = (data: any) => {
  const router: any = []
  const circle = (data: any) => {
    return data.map((item: any) => {
      if (item.children) {
        circle(item.children)
      } else {
        router.push({ route: item.route, component: item.component, para: item.para ? item.para : '' })
      }
    })
  }
  circle(data)
  return router
}

// 得到y-m-d日期
const formatDate = (now: any) => {
  let y
  let m
  let d
  y = now.getFullYear();
  m = now.getMonth() + 1;
  d = now.getDate();
  return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
}
// 得到h-mi-s时间
const formatDate2 = (now: any) => {
  let h
  let mi
  let s
  h = now.getHours()
  mi = now.getMinutes()
  s = now.getSeconds()
  return (h < 10 ? "0" + h : h) + ':' + (mi < 10 ? "0" + mi : mi) + ':' + (s < 10 ? "0" + s : s);
}

const formateTime = (now: any) => {
  let y
  let m
  let d
  let h
  let mi
  let s
  y = now.getFullYear();
  m = now.getMonth() + 1;
  d = now.getDate();
  h = now.getHours()
  mi = now.getMinutes()
  s = now.getSeconds()
  return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + ' ' + (h < 10 ? "0" + h : h) + ':' + (mi < 10 ? "0" + mi : mi) + ':' + (s < 10 ? "0" + s : s);
}

const slice_date = (date: string) => date.slice(0, 10)

// 去除特殊字符串
const delete_string = (str: string) => {
  if (str === null || str === undefined) {
    return ''
  }
  str = String(str)
  str = str.replace(/\s*/g, "")
  return str.replace(/[\^\,\@\!\|\~\`\(\)\#\+\=\$\%\^\&\*\{\}\:\;\"\'\<\>\?]/g, '')
}

// 去除换行回车符
const delete_enter = (str: string) => {
  if (str === null || str === undefined) {
    return ''
  }
  str = String(str)
  str = str.replace(/\ +/g, "");
  str = str.replace(/[\r\n]/g, "");
  return str
}

// 检测输入
const test_input = {
  tel: (tel: any) => {
    const t = Number(tel)
    if (isNaN(t) || t < 13000000000 || t >= 20000000000) {
      return false
    } else {
      return true
    }
  },
  filter_string: (str: string) => {
    const arr = ["'", '"', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')',]
    let str_new = String(str)
    for (let i = 0; i < str_new.length; i++) {
      if (str_new[i] == ' ') {
        return true
      }
      for (let j = 0; j < arr.length; j++) {
        if (str_new[i] === arr[j]) {
          return true
        }
      }
    }
    return false
  },
  contain_chinese: (str: string) => {
    if (escape(str).indexOf("%u") < 0) { return false } else { return true }
  }
}

const find_value = (data: any, key1: string, value1: string) => {
  for (let i in data) {
    let item = data[i]
    if (item[key1] == value1) {
      return item
    }
  }
  return {}
}

const isInteger = (obj: number) => {
  return obj % 1 === 0
}

// 对比数组，找出不同。每个数组都只能是字符串数组
const match_arr = (arr_old: [any], arr_new: [any]) => {
  const add_con = []
  const delete_con = []
  for (let i in arr_old) {
    if (arr_new.indexOf(arr_old[i]) == -1) {
      delete_con.push(arr_old[i])
    }
  }
  for (let i in arr_new) {
    if (arr_old.indexOf(arr_new[i]) == -1) {
      add_con.push(arr_new[i])
    }
  }
  return { add_con, delete_con }
}

function doHandleMonth(month: any) {
  var m = month;
  if (month.toString().length == 1) {
    m = "0" + month;
  }
  return m;
}

function getDay(day: any) {
  var today = new Date();
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
  today.setTime(targetday_milliseconds); //注意，这行是关键代码
  var tYear = today.getFullYear();
  var tMonth = today.getMonth();
  var tDate = today.getDate();
  tMonth = doHandleMonth(tMonth + 1);
  tDate = doHandleMonth(tDate);
  return tYear + "-" + tMonth + "-" + tDate;
}
const replace_mysql_time = (time: string) => {
  if (time) {
    return time.slice(0, 16).replace('T', ' ')
  } else {
    return '暂无'
  }
}

// 预填箱列
const data_columns = [
  {
    title: '登录账号/手机号',
    key: 'username',
    name: '登录账号',
    default: '',
    hidden: true,
  },
  {
    key: 'id',
    name: 'id',
    default: '',
    hidden: true,
    width: 80,
  },
  {
    key: 'vesselEname',
    name: '船名',
    is_require: true,
    default: '',
    hidden: false,
  },
  {
    key: 'vesselCname',
    name: '中文船名',
    is_require: true,
    default: '',
    hidden: false,
    disabled_add: true,
  },
  {
    key: 'cntrNo',
    name: '箱号',
    is_require: true,
    default: '',
    hidden: false,
  },
  {
    key: 'cntrModel',
    name: '箱型',
    is_require: true,
    default: '',
    hidden: false,
  },
  {
    key: 'voyageNo',
    name: '航次',
    is_require: true,
    default: '',
    hidden: false,
  },
  {
    key: 'billNbr',
    name: '提单号',
    is_require: true,
    default: '',
    hidden: false,
  },
  {
    key: 'sealNo',
    name: '铅封号',
    is_require: true,
    default: '',
    hidden: false,
  },
  {
    key: 'productName',
    name: '品名',
    is_require: true,
    default: '',
    hidden: false,
  },
  {
    key: 'quantity',
    name: '数量',
    is_require: true,
    default: '',
    hidden: false,
    type: 'number',
  },
  {
    key: 'unit',
    name: '单位',
    is_require: false,
    default: '',
    hidden: false,
  },
  {
    key: 'carNumber',
    name: '车号',
    is_require: true,
    default: '',
    hidden: false,
  },
  {
    key: 'remarks',
    name: '备注',
    is_require: false,
    default: '',
    hidden: false,
  },
  {
    key: 'status',
    name: '入场状态',
    is_require: false,
    default: 0,
    hidden: false,
    disabled_add: true,
  },
  {
    key: 'time',
    name: '提交时间',
    default: '',
    hidden: true,
  },
]

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  post_data,
  add_index_for_fun,
  format_router,
  formatDate,
  formatDate2,
  test_input,
  delete_string,
  slice_date,
  formateTime,
  find_value,
  isInteger,
  match_arr,
  data_columns,
  delete_enter,
  isMobile,
  getDay,
  replace_mysql_time,
}