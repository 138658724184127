// 404

import { Result, Button } from 'antd';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <h1 style={{ textAlign: 'center', marginTop: 20 }}>
    404 NO FOUND
  </h1>
);
