// const head = 'http://106.13.34.154:3011/'
// const head = 'http://localhost:3012/'
const head = 'http://api.hthy56.com/'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    head,
    login: head + 'login_prepare',//登录
    logout: head + 'logout',//登出
    get: head + 'get_prepare',//获取数据
    delete: head + 'delete_prepare',//删除数据
    update: head + 'update_prepare',//更新数据
    add: head + 'add_prepare',//添加数据
    update_password: head + 'update_password',//修改密码
    update_client_name: head + 'update_client_name',//修改账户名称
    send_code: head + 'send_code',//发送验证码
    reg_new_user: head + 'reg_new_user',//注册新用户

    // 运抵接口
    get_asgn: head + 'get_asgn',//获取数据
    get_asgn_chart: head + 'get_asgn_chart',//获取运抵记图表数据
    add_asgn: head + 'send_asgn',//添加数据

    // 高速上报接口
    get_sys_data_gaosu: head + 'get_sys_data_gaosu',//获取系统上传的数据
    get_sys_data_new_gaosu: head + 'get_sys_data_new_gaosu',//获取最新的数据
    add_data_gaosu: head + 'add_data_gaosu',//添加数据
}
