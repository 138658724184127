// 入口页

import { useState, useEffect } from 'react'
import RouterConfig from './common/router_config'
import { BrowserRouter as Router } from 'react-router-dom'
import './styles/index.css'
import './styles/common.css'
import './App.css'
import 'antd/dist/antd.css'
import Login from './components/login/index'
import { ConfigProvider, BackTop, Popover, Input, Button, notification } from 'antd'
import url from './common/url_config'
import fun from './common/common_fun'
import zhCN from 'antd/es/locale/zh_CN';
import cookie from 'react-cookies'
import { UpCircleOutlined, PoweroffOutlined, UserOutlined, IdcardOutlined, ReadOutlined, SolutionOutlined } from '@ant-design/icons';
import Menu from './components/menu/index'
import logo_mini from './img/logo_mini.jpg'

function App() {
  let [username, setUsername] = useState('')
  let [user_account, set_user_account] = useState(0)//当前余额   
  let [current_url, set_current_url] = useState(false)//当前页面是否需要左侧菜单   
  let [show_zhongji_has_complete, set_show_zhongji_has_complete] = useState(true)//显示重机已完成箱子   
  let [work_mode, set_work_mode] = useState('') //重机当前页面

  useEffect(() => {
    set_current_url(window.location.href.indexOf('print') !== -1 || window.location.href.indexOf('/zhongji/') !== -1)
    setUsername(cookie.load('username'))

    let temp_url = window.location.href
    if (temp_url.indexOf('/zhongji/in_yard') !== -1) {
      set_work_mode('in_yard')
    }
    if (temp_url.indexOf('/zhongji/out_yard') !== -1) {
      set_work_mode('out_yard')
    }

    if (cookie.load('login_role') && cookie.load('login_role') === '客户') {
      fun['post_data'](url['head'] + 'get_user_list', {}).then(res => {
        if (res.status) {
          let rows = res.result.rows
          if (rows.length === 1) {
            set_user_account(rows[0]['当前余额'] || 0)
            if (rows[0]['当前余额'] < 1000) {
              notification.error({
                message: '当前账户余额为' + rows[0]['当前余额'] + '元，已小于1000元，为避免您的正常使用，请及时联系客服充值',
                placement: 'bottomRight',
                duration: null,
              })
            }
          }
        }
      })
    }
  }, [])

  // 退出登录
  const log_out = () => {
    cookie.save('username', '', { path: '/' })
    fun['post_data'](url['logout']).then(res => {
      window.location.href = '/login'
    })
  }

  if (!username || window.location.href.indexOf('login') !== -1) {
    return <Login />
  }

  return (
    <div className='main_root'>
      <BackTop>
        <div style={{ backgroundColor: '#1088e9', color: 'white', opacity: 0.5, width: 40, height: 40, textAlign: 'center', lineHeight: '48px' }} ><UpCircleOutlined style={{ fontSize: 25 }} /></div>
      </BackTop>
      <Router>
        <div>
          {/* 左侧菜单，打印页面和重机司机不显示 */}
          {!current_url && <div style={{ width: 140, position: 'fixed', left: 0, top: 0, height: '100%', backgroundColor: '#001529', zIndex: 1000 }}>
            <div style={{ borderBottom: '4px solid #002a52', color: '#ffffff', marginBottom: 6 }}>
              <div style={{ textAlign: 'center', height: 40, color: '#ffffff', overflow: 'hidden' }}>
                <img style={{ width: 27, height: 27, marginTop: -5, borderRadius: 5 }} src={logo_mini} /><span style={{ lineHeight: '40px', fontSize: 15, marginLeft: 10, fontWeight: 'bold' }}>浩田集装箱</span>
              </div>
            </div>
            <Menu />
            <div style={{ color: '#ffffff', marginTop: 20, marginLeft: 5, width: 120 }}>
              {cookie.load('login_role') === '客户' && <div style={{ marginTop: 5 }}>当前余额：<b>{user_account}</b>元</div>}
              <div style={{ marginTop: 5 }}><UserOutlined />{cookie.load('login_role')} {username || '登陆用户'}</div>
              <div style={{ cursor: 'pointer', marginTop: 5 }} onClick={() => log_out()}><PoweroffOutlined /> 退出登录</div>
            </div>
          </div>}

          {/* 重机司机头部菜单 */}
          {window.location.href.indexOf('/zhongji/') !== -1 && <div style={{ fontSize: 17, height: 40, backgroundColor: '#1ea0fa', color: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ marginLeft: 10 }}>
              <span onClick={() => {
                set_work_mode('in_yard')
                window.location.href = '/zhongji/in_yard'
              }} style={{ cursor: 'pointer', fontSize: work_mode === 'in_yard' ? 18 : 14, fontWeight: work_mode === 'in_yard' ? 'bold' : 'normal' }}>落箱作业</span>
              <span onClick={() => {
                set_work_mode('out_yard')
                window.location.href = '/zhongji/out_yard'
              }} style={{ marginLeft: 10, cursor: 'pointer', fontSize: work_mode === 'out_yard' ? 18 : 14, fontWeight: work_mode === 'out_yard' ? 'bold' : 'normal' }}>提箱作业</span>
            </div>

            {/* 当前重机完成数 */}
            {show_zhongji_has_complete && <i onClick={() => {
              set_show_zhongji_has_complete(false)
              setTimeout(() => {
                set_show_zhongji_has_complete(true)
              }, 300)
            }} style={{ fontSize: 16, cursor: 'pointer' }}>本次已落 {cookie.load('current_complete_num_in_yard')}&nbsp;&nbsp;已提 {cookie.load('current_complete_num_out_yard')}&nbsp;&nbsp;<span style={{ fontSize: 12 }}>点击刷新</span></i>}

            <div style={{ marginRight: 10 }}>
              <b>{cookie.load('login_role')} {username || '用户名称'}</b> 欢迎使用
              <Button icon={<PoweroffOutlined />} onClick={() => {
                log_out()
              }} style={{ marginLeft: 10 }}>退出登录</Button>
            </div>
          </div>}

          {/* 页面主体部分 */}
          <div style={{ width: '100%', backgroundColor: '', marginTop: 0, overflow: 'auto', }}>
            <div style={{ width: current_url ? '100%' : 'calc(100% - 140px)', float: 'right', }} className='router'>
              <ConfigProvider locale={zhCN}><RouterConfig /></ConfigProvider>
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;